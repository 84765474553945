import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';

import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { openTransactionsDialog } from '../../redux/slices/transactionsDialog';

import { setGameCategories } from '../../redux/slices/game';

import DepositWithdrawDialog from './header/DepositWithdrawDialog';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from './header/withdraw/WithdrawFormDialog';
import { openLoginSignup } from '../../redux/slices/authPages';
import useRedirect from '../../hooks/useRedirect';
import LoadingScreen from '../../components/LoadingScreen';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  backgroundColor: 'black',
  // backgroundImage: `url(${require('../../assets/bg.jpg')})`,
  // backgroundSize: '200% auto',
  // backgroundPosition: 'center',
  // backgroundRepeat: 'repeat-y',
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.only('xs')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

// ----------------------------------------------------------------------

function updatePos(elem, width, widgetState) {
  console.log(`widgetState = ${widgetState}`);
  if (width < 600 && widgetState === 'widgetClose') {
    elem.style.setProperty('bottom', '25vw', 'important');
    elem.style.setProperty('right', '2vw', 'important');
    elem.style.opacity = 1;
  } else if (width < 600 && widgetState === 'widgetOpen') {
    elem.style.setProperty('bottom', '0px', 'important');
    elem.style.setProperty('right', '0px', 'important');
    elem.style.opacity = 1;
  } else if (width < 600) {
    elem.style.setProperty('bottom', '25vw', 'important');
    elem.style.setProperty('right', '2vw', 'important');
    elem.style.opacity = 1;
  } else {
    elem.style.setProperty('bottom', '25px', 'important');
    elem.style.setProperty('right', '25px', 'important');
    elem.style.opacity = 1;
  }
}

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate, onChangeLang } = useLocales();

  const dispatch = useDispatch();

  const navigate = useRedirect();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const params = useParams();

  const [element, setElement] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  const [height, setHeight] = useState(window.innerHeight);

  const intervalRef = useRef(null);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    if (isAuthenticated) promises.push(getUserMemberBalance());
  }, [params, isAuthenticated]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) {
      getUserMemberBalance();
      intervalRef.current = setInterval(() => {
        getUserMemberBalance();
      }, 30000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (isVisible) {
      if (localStorage.getItem(`temp-lang`)) {
        onChangeLang(localStorage.getItem(`temp-lang`));
        localStorage.setItem(`lang${params?.domain?.toUpperCase()}`, localStorage.getItem(`temp-lang`));
        localStorage.removeItem(`temp-lang`);
        navigate('/');
        window.location.reload();
      } else if (!localStorage.getItem(`lang${params?.domain?.toUpperCase()}`)) {
        localStorage.setItem(`lang${params?.domain?.toUpperCase()}`, 'en');
        onChangeLang('en');
      } else {
        onChangeLang(localStorage.getItem(`lang${params?.domain?.toUpperCase()}`));
      }
    }
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    if (siteSettings?.config_analytics) {
      const _ga = siteSettings?.config_analytics?.split(',');

      // Multiple products (previously known as trackers)
      ReactGA.initialize(
        _ga.map((item) => ({
          // measurement ID
          trackingId: item,
          // gaOptions: {...}, // optional
          // gtagOptions: {...}, // optional
        }))
        //   [
        //   {
        //     // measurement ID
        //     trackingId: _ga[0],
        //     // gaOptions: {...}, // optional
        //     // gtagOptions: {...}, // optional
        //   },
        // ]
      );
    }

    if (siteSettings?.config_fb_pixel) {
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init(siteSettings?.config_fb_pixel, options);

      ReactPixel.pageView(); // For tracking page view
    }

    if (siteSettings?.config_livechat) {
      const respondIo = JSON.parse(siteSettings?.config_livechat);
      const script = document.createElement('script');

      script.id = respondIo?.id;
      script.src = respondIo?.src;
      script.async = true;

      document.head.appendChild(script);
    }
  }, [siteSettings]);

  useEffect(() => {
    const selector = '#_68625 > div > iframe';

    let observer;

    function handleMutation(mutations) {
      if (document.querySelector(selector)) {
        observer?.disconnect();
        setElement(document.querySelector(selector));
      }
    }

    if (document.querySelector(selector)) {
      setElement(document.querySelector(selector));
    } else {
      const observer = new MutationObserver(handleMutation);
      observer?.observe(document.body, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer?.disconnect(); // Cleanup when the component unmounts
      };
    }
  }, []); // The empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (element) {
      console.log('iframe Element is ready');
      element.style.opacity = 0;

      // it seems their js sets the bottom position with a slight delay after load, so we wait 1 sec then only set our css
      setTimeout(() => {
        const _widgetState = element?.getAttribute('state');
        updatePos(element, width, _widgetState);

        // observe attribute change
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
              const widgetState = element?.getAttribute('state');

              console.log(`widgetState = ${widgetState}`);

              console.log('attributes changed');
              // set the css again
              updatePos(element, width, widgetState);
            }
          });
        });

        observer.observe(element, {
          attributes: true, // configure it to listen to attribute changes
        });
      }, 2000);
    }
  }, [element]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
        <Footer />
        <Box
          sx={{
            height: '64px',
            background: 'linear-gradient(to bottom, rgba(56, 56, 56, 1) 0%, rgba(0, 0, 0, 1) 100%)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 100,
            boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
            display: 'block',
            p: '5px 0',
            right: 0,
            borderTop: `2px solid ${palette.bottomMenu.depositBorder}`,
            width: isDesktop && !isCollapse ? `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)` : '100%',
            marginLeft: isDesktop && !isCollapse ? `calc(${NAVBAR.DASHBOARD_WIDTH}px)` : '0',
          }}
        >
          <Grid container>
            <Grid
              onClick={() => navigate('/games/slots')}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
              }}
            >
              <Image
                sx={{
                  cursor: 'pointer',
                  height: '32px',
                  width: '32px',
                  img: {
                    objectFit: 'contain',
                  },
                }}
                src={require('../../assets/menu/nav-slot.png')}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                  mt: '2px',
                  background:
                    'linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }}
              >
                {translate('slots')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
              }}
              onClick={() => navigate('/games/live-casino')}
            >
              <Image
                sx={{
                  cursor: 'pointer',
                  height: '32px',
                  width: '32px',
                  img: {
                    objectFit: 'contain',
                  },
                }}
                src={require('../../assets/menu/nav-bottom-casino.png')}
                width={32}
                height={32}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: 'gold',
                  fontWeight: 600,
                  mt: '2px',
                  background:
                    'linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }}
              >
                {translate('casino')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
              }}
            >
              <Box
                onClick={() => {
                  if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                  else
                    dispatch(
                      openTransactionsDialog({
                        open: true,
                        isDeposit: true,
                      })
                    );
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: palette.bottomMenu.depositBg,
                  border: `2px solid ${palette.bottomMenu.depositBorder}`,
                  flexDirection: 'column',
                  height: '68px',
                  marginTop: '-30px',
                  width: '68px',
                  borderRadius: '100%',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 4px 1px #FFF858',
                }}
              >
                <Image
                  src={require('../../assets/menu/nav-bottom-wallet.png')}
                  alt="Deposit"
                  sx={{
                    cursor: 'pointer',
                    height: '32px',
                    img: {
                      objectFit: 'contain',
                    },
                  }}
                />
                <Typography
                  sx={{ fontFamily: 'sans-serif', fontSize: '12px', fontWeight: 700, color: 'gold', mt: '2px' }}
                  className="deposit-text-animation"
                >
                  {translate('wallet')}
                </Typography>
              </Box>
              {/* <Iconify icon={'fluent:share-24-filled'} width={24} height={24} sx={{ color: '#2283f6' }} />
              <Typography
                sx={{ fontSize: '14px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
              >
                Share
              </Typography> */}
            </Grid>
            <Grid
              onClick={() => navigate('promotion')}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
              }}
            >
              <Image
                sx={{
                  cursor: 'pointer',
                  height: '32px',
                  width: '32px',
                  img: {
                    objectFit: 'contain',
                  },
                }}
                src={require('../../assets/menu/nav-bottom-promo.png')}
                width={32}
                height={32}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                  mt: '2px',
                  background:
                    'linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent',
                }}
              >
                {translate('promotion')}
              </Typography>
            </Grid>
            <Grid
              onClick={() => {
                if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                else navigate('/user/account');
              }}
              item
              xs={12 / 5}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                mt: '2px',
                background:
                  'linear-gradient(90deg, #BC8001 0%, #C8941C 17.62%, #FAEB9A 26.88%, #C8941C 32.84%, #C8941C 38.5%, #FAEB9A 43.4%, #FAEB9A 52.96%, #C8941C 59.75%, #C8941C 63.11%, #FAEB9A 72.02%, #C8941C 79.16%, #BC8001 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              }}
            >
              <Image
                sx={{
                  cursor: 'pointer',
                  height: '32px',
                  width: '32px',
                  img: {
                    objectFit: 'contain',
                  },
                }}
                src={require('../../assets/menu/nav-bottom-user.png')}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'sans-serif',
                  whiteSpace: 'nowrap',
                  color: palette.bottomMenu.color,
                  fontWeight: 600,
                }}
              >
                {translate('account')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MainStyle>

      {/* {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={() => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )} */}

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
