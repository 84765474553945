/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { setSession } from '../utils/jwt';
import conObj from '../utils/connection-assistant';
import { getCountryFromUrl } from '../utils/formatString';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: !!localStorage.getItem(`accessToken-${getCountryFromUrl()}`),
  isInitialized: false,
  user: localStorage.getItem(`userInfo-${getCountryFromUrl()}`)
    ? JSON.parse(localStorage.getItem(`userInfo-${getCountryFromUrl()}`))
    : null,
  userProfile: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, userProfile } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      userProfile,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const { allLangs } = useLocales();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const country = getCountryFromUrl();

      const accessToken = localStorage.getItem(`accessToken-${country}`);

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const payStatus = params.get('status');
      const payOrderId = params.get('orderId');
      const referralCode = params.get('aff');
      const lang = params.get('lang');
      

      if (referralCode) {
        localStorage.setItem(`referralCode-${country}`, referralCode);
      }

      if (lang) {
        const langExist = allLangs.find((_lang) => _lang.value === lang)
        if(langExist) {
          localStorage.setItem(`temp-lang`, langExist.value) ;
        }
      }

      if (payStatus) {
        localStorage.setItem(`status-${country}`, payStatus);
      }

      if (payOrderId) {
        localStorage.setItem(`orderId-${country}`, payOrderId);
      }

      if (accessToken) {
        setSession(accessToken);

        const response = await conObj.post('profile.json');
        const { first_name, username, full_name, last_name, email } = response.data.data.data;
        const _user = { displayName: first_name, username, full_name, last_name, email };

        localStorage.setItem(`userInfo-${country}`, JSON.stringify(_user));

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: _user,
            userProfile: response.data.data.data,
          },
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            userProfile: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
          userProfile: null,
        },
      });
    }
  };

  const login = async (requestData) => {
    const country = getCountryFromUrl();
    localStorage.removeItem(`accessToken-${country}`);
    localStorage.removeItem(`userInfo-${country}`);

    const response = await conObj.post('login.json', requestData);

    const {
      token,
      data: { first_name, username, full_name, last_name, email },
    } = response.data.data;

    const _user = { displayName: first_name, username, full_name, last_name, email };

    localStorage.setItem(`userInfo-${country}`, JSON.stringify(_user));

    if (token) {
      setSession(token);
      initialize();
      dispatch({
        type: 'LOGIN',
        payload: {
          user: _user,
        },
      });
    } else throw new Error(response.data?.data?.msg);
  };

  const register = async (requestData) => {
    const country = getCountryFromUrl();

    localStorage.removeItem(`accessToken-${country}`);
    localStorage.removeItem(`userInfo-${country}`);

    const response = await conObj.post('register.json', requestData);

    const {
      token,
      data: { first_name },
    } = response.data.data;

    const _user = {
      displayName: first_name,
      username: requestData?.['user-username'],
      email: requestData?.['user-email'],
      full_name: requestData?.['user-full_name'],
    };

    localStorage.setItem(`userInfo-${country}`, JSON.stringify(_user));

    setSession(token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: _user,
      },
    });
    initialize();
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
